import { BaseElement, html, css } from 'Elements';
import { getFormControls } from '@shoelace-style/shoelace/dist/utilities/form.js';
import { Fetcher, Session, Notify, Sleep } from 'Utils';
import localStyles from './styles.js';
import App from 'App';

class Form extends BaseElement {
  static get styles() {
    return [
      //super.styles,
      localStyles,
      css`

        :host {
          display:none;
        }

        sl-input::part(input) {
          font-family: Calibri;
          font-size:15px;
        }

        .buttons {
          text-align:right;
          margin-top:20px;
        }

        m-icon[name="lock"] {
          float:right;
          margin-right:7px;
          font-size:20px;
        }

        p {
          margin-left:20px;
          display:flex;
          gap:10px;
          line-height:25px;
        }

        .line {
          display:flex;
          gap:10px;
          line-height:30px;
        }

        .line span {
          min-width:100px;
        }

        .line sl-select {
          width:100%;
        }

        .line sl-textarea,
        .line sl-input {
          width:100%;
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title: 'Notifications',
          }
        },
        french:{
          translation: {
            title: 'Notifications',
          }
        }
      }
    ]
  }
  
  toggleForm(form, readonly) {
    const elements = getFormControls(form);
    for (let i = 0, len = elements.length; i < len; ++i) {
      //elements[i].readonly = readonly;
      elements[i].disabled = readonly;
    }
  }

  async show() {
    this.settings = null;
    this.requestUpdate();
    
    this.style.display = 'block';
    await Sleep(1000);
    const response = await Fetcher.post('private/user/account/notifications/settings', { deviceId: App.config.deviceId });
    this.settings = response.data;
    this.requestUpdate();

    await Sleep(1);

    for (const channel of this.settings) {
      const el = this.shadowRoot.querySelector(`sl-switch[data-channel="${channel.channel}"]`);
      if (el) el.checked = true;
    }
  }

  hide() {
    this.style.display = 'none';
  }

  async onSwitchClick(ev) {
    const channel = ev.target.getAttribute('data-channel');
    const channelTitle = ev.target.nextSibling.textContent;
    if (ev.target.checked) {
      await this.subscribe(channel, channelTitle);
    } else {
      await this.unsubscribe(channel, channelTitle);
    }
  }

  urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  async subscribe(channel, channelTitle) {
    try {
      const registration = await navigator.serviceWorker.register('/sw.js');

      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing;
        newWorker.addEventListener('statechange', () => {
          if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
            // Informe le Service Worker de prendre le contrôle immédiatement
            newWorker.postMessage({ action: 'skipWaiting' });
          }
        });
      });

      await navigator.serviceWorker.ready;

      if (!registration.pushManager) {
        window.dispatchEvent(new CustomEvent('show-install-dialog'));
        return;
      }

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        // eslint-disable-next-line no-undef
        applicationServerKey: this.urlBase64ToUint8Array(VAPID_PUB_KEY)
      });
      
      const payload = {
        channel,
        channelTitle,
        subscription,
        deviceId: App.config.deviceId,
        userAgent: navigator.userAgent,
        languages: navigator.languages,
        platform: navigator.platform,
        product: navigator.product,
        productSub: navigator.productSub,
        vendor: navigator.vendor,
      }

      const response = await Fetcher.post('private/user/account/notifications/subscribe', payload);
      if (response.ok) {
        Notify.success({ title:'Notification '+channelTitle, message:`Vous êtes maintenant abonné au canal ${channelTitle}` });
      }
    } catch (e) {
      //if (e.message.includes('denied')) return;
      console.error('Service Worker registration or subscription failed', e.message);
      console.error(e);
      Notify.error(`Impossible de s'abonner au canal ${channelTitle}:\n${e.message}\n${e.stack}`, 1000*60);
    }
    //Fetcher.post('/api/notifications/subscribe', { channel });
  }

  async unsubscribe(channel, channelTitle) {
    const response = await Fetcher.post('private/user/account/notifications/unsubscribe', {
      channel,
      channelTitle,
      deviceId: App.config.deviceId
    });
    if (response.ok) {
      Notify.success({ title:'Notification '+channelTitle, message:`Vous êtes maintenant désabonné du canal ${channelTitle}` });
    }
  }

  async onTestMessageChange(ev) {
    this.shadowRoot.querySelector('sl-button').disabled = ev.target.value ? false : true;
  }

  async testMessageSend() {
    if (!Session.isBoss()) return;

    const channelId = this.shadowRoot.querySelector('sl-select').value;
    const channelTitle = this.shadowRoot.querySelector('sl-select').querySelector(`[value="${channelId}"]`).textContent;
    const title = this.shadowRoot.querySelector('sl-input').value;

    const payload = {
      channel:channelId,
      title:title || channelTitle,
      message:this.shadowRoot.querySelector('sl-textarea').value
    }

    await Fetcher.post('private/admin/tests/notifications/send', payload);
  }

  
  render() {
    return html`
      <form>
        <section-header size="medium">${this._tl('title')}</section-header>
        <br/>
        Abonnez vous aux différents canaux de notifications et restez informé en temps réel.<br/><br/>
        ${this.settings 
          ? html`
            <box-styled variant="warning">
              Vous devez activer les notifications sur chaque appareil que vous utilisez.<br/>
            </box-styled><br/>

            <h3>Canaux pour vous</h3>
            <p><sl-switch data-channel="${ENV}-attack-ddosia" @sl-change="${this.onSwitchClick}"></sl-switch><span>Attaques DDoSia</span></p>
            <p><sl-switch data-channel="${ENV}-platform-updates" @sl-change="${this.onSwitchClick}"></sl-switch><span>Nouveautés de la plateforme</span></p>
            <br/>
            <h3>Canaux dédié au SOC HubOne <m-icon name="lock"></m-icon></h3>
            <p><sl-switch data-channel="${ENV}-rt-incidents" @sl-change="${this.onSwitchClick}"></sl-switch><span>Sentinel - incidents</span></p>
            ${Session.isBoss() 
              ? html`
                <br/>
                <h3>Pour les développeurs et administrateurs de la plateforme<m-icon name="lock"></m-icon></h3>
                <p><sl-switch data-channel="${ENV}-platform-push-test" @sl-change="${this.onSwitchClick}"></sl-switch><span>Tests</span></p>
                <br/>
                <h3>Envoyer une notification<m-icon name="lock"></m-icon></h3>
                <div class="line">
                  <span>Canal</span>
                  <sl-select size="small" value="${ENV}-platform-push-test">
                    <sl-option value="${ENV}-platform-updates">Nouveautés de la plateforme</sl-option>
                    <sl-option value="${ENV}-platform-push-test">Test</sl-option>
                  </sl-select>              
                </div>
                <br/>
                <div class="line">
                  <span>Titre</span>
                  <sl-input size="small" help-text="Valeur par défaut: titre du canal"></sl-input>
                </div>
                <br/>
                <div class="line">
                  <span>Message</span>
                  <sl-textarea size="small" placeholder="Tapez votre message" @sl-input="${this.onTestMessageChange}"></sl-textarea>
                </div>
                <div class="buttons">
                  <sl-button size="small" variant="primary" disabled @click="${this.testMessageSend}">Envoyer</sl-button>
                </div>`
              : ''
            }
          ` 
          : html`<sl-progress-bar indeterminate></sl-progress-bar>`
        }
      </form>
      <br/><br/>
    `
  }

}

customElements.define('form-user-account-notifications', Form);