import { BaseElement, html, css, formCss } from 'Elements';
import App from 'App';
import localStyles from './styles.js';

class Sounds extends BaseElement {
  static get styles() {
    return [
      //super.styles,
      localStyles,
      formCss,
      css`
        :host {
          display:none;
        }

        sl-switch {
          min-height:40px;
        }
      `
    ];
  }
  
  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title: 'Sounds alerts',
            sounds_enabled: 'Sound is enabled',
            sounds_disabled: 'Sound is disabled',
          }
        },
        french:{
          translation: {
            title: 'Alertes sonores',
            sounds_enabled: 'Le son est activé',
            sounds_disabled: 'Le son est désactivé',
          }
        }
      }
    ]
  }

  static get properties() {
    return {
      soundEnabled: { type: Boolean }
    }
  }

  constructor() {
    super();
    this.soundEnabled = localStorage.getItem(App.config.localKeys.sound) || false;
  }

  async handleSubmitInfos(ev) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  toggleSound(ev) {
    this.soundEnabled = ev.target.checked;
    if (this.soundEnabled) {
      localStorage.setItem(App.config.localKeys.sound, this.soundEnabled);
      this.playSound();
    } else {
      localStorage.removeItem(App.config.localKeys.sound);
    }
  }

  playSound() {
    window.dispatchEvent(new CustomEvent('playsound', { detail:'info' }));
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('playsound', { detail:'warn' }));
    }, 500);
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('playsound', { detail:'error' }));
    }, 1000);
  }  

  render() {
    return html`
      <section-header size="medium">${this._tl('title')}</section-header>
      <br/>
      <form @submit="${this.handleSubmitInfos}">
        <sl-switch size="small" ?checked=${this.soundEnabled} @sl-change=${this.toggleSound}>
          <div style="width:350px;">
            ${this.soundEnabled 
              ? html`${this._tl('sounds_enabled')}`
              : html`${this._tl('sounds_disabled')}`
            }
          </div>
        </sl-switch>
      </form>
    `
  }

}

customElements.define('form-user-account-sounds', Sounds);