import { BaseElement, html, css, formCss } from 'Elements';
import { Fetcher, Session, Notify } from 'Utils';
import localStyles from './styles.js';

class Credentials extends BaseElement {
  static get styles() {
    return [
      localStyles,
      formCss,
      css`
        .buttons {
          margin-top:40px;
          text-align:right;
        }

        .flex {
          display:flex;
          justify-content:space-between;
          line-height:40px;
          width:100%;
          font-size:0.9em;
        }

        .comment {
          font-size:1em;
        }

        iframe {
          width:600px;
          height:80vh;
          border:0;
          display:none;
        }

        box-styled {
          font-size:0.9em;
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            auth_base: 'Basic authentication',
            auth_2fa: 'Two-factor authentication',
            configure: 'Configure ways to sign in.',
            configure_auth: 'Configure Two-factor authentication',
            password: 'Password',
            otp: 'OTP',
            cancel:'Cancel',
            unknow: 'Unknow',
            add:'Add',
            update:'Update your password',
            created:'Created at',
            delete:'Delete',
            wait:'Please wait ...',
            notify:{
              email:{
                title:'An email has been sent to you',
                message:'It contains a link that will allow you to change your password',
              }
            }
          }
        },
        french:{
          translation: {
            auth_base: 'Authentification de base',
            auth_2fa: 'Authentification à deux facteurs',
            configure: 'Configurez les façons de vous authentifier.',
            configure_auth: 'Configurer l\'authentification à deux facteurs',
            add:'Ajouter',
            otp: 'OTP',
            password: 'Mot de passe',
            cancel:'Annuler',
            unknow: 'Inconnu',
            update:'Mettre à jour',
            delete:'Supprimer',
            created:'Créé le',
            wait:'Merci de bien vouloir patienter ...',
            no_otp:`Aucun OTP configuré. Nous vous recommandons de configurer un OTP pour sécuriser votre compte.`,
            notify:{
              email:{
                title:'Un email vous a été envoyé',
                message:'Il contient un lien qui vous permettra de changer votre mot de passe',
              }
            },
          }
        }
      }
    ]
  }

  static get properties() {
    return {
      methods: { type: Array }
    }
  }
  
  constructor() {
    super();
    this.receiveMessage = this.receiveMessage.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('message', this.receiveMessage);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('message', this.receiveMessage);
  }

  receiveMessage(event) {
    const path = event?.data?.href || '';
    //console.log('receiveMessage', path);

    if (path.includes('/login-actions/required-action?execution=CONFIGURE_TOTP')) {
      // /login-actions/required-action?execution=CONFIGURE_TOTP
      this.qs('#loading').style.display = 'none';
      this.qs('iframe').style.display = 'block';
    } else if (path.includes('/account/') && path.includes('session_state')) {
      // /account/?state=xxx&session_state=yyy&iss=zzz&code=aaa#/security/totp
      this.qs('#loading').style.display = 'block';
      this.qs('iframe').style.display = 'none';
      this.qs('#two-factor').hide();
      this.loadData();
    }
  } 
  
  async firstUpdated() {
    super.firstUpdated();
    this.session = await Session.get();
    this.loadData();
  }

  async loadData() {
    const response = await Fetcher.get('private/user/account/credentials');
    if (!response) return;
    const methods = response.data;
    for (const method of methods) {
      method.createdDate = new Date(method.createdDate).toLocaleString();
      method.typeText = this._tl(method.type);
    }
    this.methods = methods;
  }

  async handlePasswordUpdate(ev) {

    ev.stopPropagation();
    ev.preventDefault();

    const bt = this.qs('#bt_change_password');
    bt.disabled = true;
    bt.loading = true;

    const response = await Fetcher.post('private/user/account/change_password');

    if (response?.ok) {
      Notify.success({
        title:this._tl('notify.email.title'),
        message:this._tl('notify.email.message'),
        duration:10000
      });
    }

    bt.loading = false;
    bt.disabled = false;
  }


  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  async showConfigureOtp() {
    this.qs('#two-factor').show();
    const response = await Fetcher.post('private/user/account/credentials', { enable: true });
    if (!response) return;
    this.qs('iframe').src = response.url;
  }

  showConfirmDelete(method) {
    this.selectedMethod = method;
    this.qs('#confirm-delete').show();
    this.qs('#confirm-delete').qs('.bold').textContent = method.userLabel || '';
  }

  async handleDelete() {
    this.qs('#confirm-delete').loadingButtons();
    const response = await Fetcher.delete(`private/user/account/credentials/${this.selectedMethod.id}`);
    if (!response) return;
    this.qs('#confirm-delete').resetButtons();
    this.qs('#confirm-delete').hide();
    await this.loadData();
  }


  render() {
    
    if (!this.session) return;

    const methodsPassword = this.methods?.filter(method => method.type === 'password');
    const methodsOtp = this.methods?.filter(method => method.type === 'otp');
    

    return html`
      <section-header size="medium">${this._tl('auth_base')}</section-header>
      <br/>
      <div class="comment">${this._tl('configure')}</div><br/>
      <form>
        ${methodsPassword?.map(method => {
          return html`
            <div class="flex">
              <div>${this._tl('password')}</div>
              <div>Créé le ${method.createdDate}</div>
              <div><sl-button size="small" variant="primary" @click="${this.handlePasswordUpdate}" id="bt_change_password">${this._tl('update')}</sl-button></div>
            </div>
          `;
        })}
      </form>
      <br/><br/>
      
      <section-header size="medium">
        ${this._tl('auth_2fa')}
        <sl-button slot="right" variant="primary" @click=${this.showConfigureOtp} size="small">${this._tl('add')}</sl-button>
      </section-header>
      <form>
        ${methodsOtp?.length === 0 
          ? html`<br/><box-styled variant="warning">${this._tl('no_otp')}</box-styled><br/>` 
          : ''
        }
        ${methodsOtp?.map(method => {
          return html`
            <div class="flex">
              <div>${method.userLabel}</div>
              <div>Créé le ${method.createdDate}</div>
              <div><sl-button size="small" variant="danger" @click="${ev => this.showConfirmDelete(method)}">${this._tl('delete')}</sl-button></div>
            </div>
          `;
        })}
      </form>

      <modal-dialog id="two-factor" label="${this._tl('configure_auth')}" closable>
        <div id="loading">
          ${this._tl('wait')}
          <br/><br/><br/>
          <sl-progress-bar indeterminate></sl-progress-bar>
        </div>
        <iframe frameborder="0"></iframe>
      </modal-dialog>

      <modal-dialog id="confirm-delete" closable>
        Etes vous sur de vouloir supprimer &laquo;&nbsp;<span class="bold"></span>&nbsp;&raquo; ?
        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="danger" @click="${this.handleDelete}">${this._tl('delete')}</sl-button>
      </modal-dialog>

      <br/><br/><br/><br/>
    `
  }

}

customElements.define('form-user-account-credentials', Credentials);